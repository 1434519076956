import React from 'react'
import { WebinarCommentItemComponentProps } from '../types/webinar_comment'
import ContentWithUrl from './ContentWithUrl'
import WebinarReplyBox from './WebinarReplyBox'

const WebinarCommentItem: React.FC<WebinarCommentItemComponentProps> = ({
  webinarComment,
  pinned,
}) => {
  const statusText = {
    published: '公開されています',
    reviewing: 'あなただけに表示されています',
    // NOTE: 公開が却下されたことをエンドユーザーには伝えない
    rejected: 'あなただけに表示されています',
  }

  return (
    <div className={`${webinarComment.byAdmin && 'bg-orange'} p-3`}>
      {pinned && (
        <small className='d-block mb-2'>
          <i className='fa fa-thumb-tack mr-1'></i>
          固定されています
        </small>
      )}

      <div className='d-flex'>
        <small className='text-muted'>{webinarComment.updatedAt.substring(11, 16)}</small>
        <small className='text-muted ml-1'>{statusText[webinarComment.status]}</small>
        {webinarComment.displayName !== '' && (
          <small className='text-break ml-auto'>
            <i className='mr-1 fa fa-user' />
            {webinarComment.displayName}
            {webinarComment.byAdmin && '（セミナー運営）'}
          </small>
        )}
      </div>

      {webinarComment.byAdmin ? (
        <ContentWithUrl
          content={webinarComment.content}
          className='mt-1 text-break text-pre-wrap spec-comment-content'
        />
      ) : (
        <div className='mt-1 text-break text-pre-wrap spec-comment-content'>
          {webinarComment.content}
        </div>
      )}

      {webinarComment.webinarReplies.length > 0 && (
        <div className='mt-2'>
          {webinarComment.webinarReplies.map(webinarReply => {
            return <WebinarReplyBox key={webinarReply.id} webinarReply={webinarReply} />
          })}
        </div>
      )}
    </div>
  )
}

export default WebinarCommentItem
