import client from '../libs/client'

const commentOpen = async (webinarLaneId: number, webinarId: number) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/open_comment`
  )
}

const openAnnouncement = async (webinarLaneId: number, webinarId: number) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/open_announcement`
  )
}

const closeAnnouncement = async (webinarLaneId: number, webinarId: number) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/close_announcement`
  )
}

const updateAnnouncement = async (webinarLaneId: number, webinarId: number, content: string) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/update_announcement`,
    { announcement_text: content }
  )
}

const openQuestionnaire = async (webinarLaneId: number, webinarId: number) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/open_questionnaire`
  )
}

const closeQuestionnaire = async (webinarLaneId: number, webinarId: number) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/close_questionnaire`
  )
}

const openPollArea = async (webinarLaneId: number, webinarId: number) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/open_poll_area`
  )
}

const closePollArea = async (webinarLaneId: number, webinarId: number) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/close_poll_area`
  )
}

export {
  commentOpen,
  openAnnouncement,
  closeAnnouncement,
  updateAnnouncement,
  openQuestionnaire,
  closeQuestionnaire,
  openPollArea,
  closePollArea,
}
