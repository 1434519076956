import React, { useEffect, useState } from 'react'
import client from '../../libs/client'
import { catchAxiosError, handleAxiosError } from '../../libs/catch_axios_error'
import createWebinarAdminChannel from '../../channels/webinar_admin_channel'
import AdminWebinarCommentForm from './AdminWebinarCommentForm'
import AdminWebinarCommentBox from './AdminWebinarCommentBox'
import AdminWebinarAnnouncement from './AdminWebinarAnnouncement'
import AdminWebinarPoll from './AdminWebinarPoll'
import {
  commentOpen,
  openAnnouncement,
  closeAnnouncement,
  updateAnnouncement,
  openQuestionnaire,
  closeQuestionnaire,
} from '../../clients/webinars'
import {
  WebinarComment,
  PollQuestion,
  WebinarCommentData,
  WebinarConsoleComponentProps,
  BroadcastedAnnouncement,
  PollQuestionCreatedBroadcastData,
  SubmitAnnouncementOpen,
  SubmitAnnouncementClose,
  SubmitAnnouncement,
} from '../../types/webinar_comment'

const AdminWebinarConsole: React.FC<WebinarConsoleComponentProps> = props => {
  const [commentEnabled, setCommentEnabled] = useState<boolean>(props.commentEnabled)
  const [webinarComments, setWebinarComments] = useState<WebinarComment[]>([])
  const [announcementEnabled, setAnnouncementEnabled] = useState<boolean>(props.announcementEnabled)
  const [announcementContent, setAnnouncementContent] = useState<string>(props.announcementContent)
  const [questionnaireEnabled, setQuestionnaireEnabled] = useState<boolean>(
    props.questionnaireEnabled
  )
  const [pollEnabled, setPollEnabled] = useState<boolean>(props.pollEnabled)
  const [pollQuestions, setPollQuestions] = useState<PollQuestion[]>(props.pollQuestions)

  const handleCommentOpenedBroadcast = async () => {
    setCommentEnabled(true)
  }

  const commentClose = async () => {
    setCommentEnabled(false)
  }

  const submitAnnouncementOpen: SubmitAnnouncementOpen = async () => {
    await catchAxiosError(async () => {
      await openAnnouncement(props.webinarLaneId, props.webinarId)
    })
  }

  const submitAnnouncementClose: SubmitAnnouncementClose = async () => {
    await catchAxiosError(async () => {
      await closeAnnouncement(props.webinarLaneId, props.webinarId)
    })
  }

  const submitAnnouncement: SubmitAnnouncement = async content => {
    await catchAxiosError(async () => {
      await updateAnnouncement(props.webinarLaneId, props.webinarId, content)
    })
  }

  const handleAnnouncementOpened = async () => {
    setAnnouncementEnabled(true)
  }

  const handleAnnouncementClosed = async () => {
    setAnnouncementEnabled(false)
  }

  const handleAnnouncementUpdated = async (broadcastedAnnouncement: BroadcastedAnnouncement) => {
    setAnnouncementContent(broadcastedAnnouncement.content)
  }

  const handleQuestionnaireOpened = async () => {
    setQuestionnaireEnabled(true)
  }

  const handleQuestionnaireClosed = async () => {
    setQuestionnaireEnabled(false)
  }

  const handlePollAreaOpened = async () => {
    setPollEnabled(true)
  }

  const handlePollAreaClosed = async () => {
    setPollEnabled(false)
  }

  const handlePollQuestionCreated = async (broadcastData: PollQuestionCreatedBroadcastData) => {
    setPollQuestions(prevPollQuestions => {
      const pollQuestion = {
        id: broadcastData.id,
        content: broadcastData.content,
        status: broadcastData.status,
        pollChoices: [],
      }
      return [...prevPollQuestions, pollQuestion]
    })
  }

  const handlePollQuestionBroadcast = async (broadcastData: { pollQuestions: PollQuestion[] }) => {
    setPollQuestions(broadcastData.pollQuestions)
  }

  const fetchWebinarComments = async (broadcastedData?: WebinarCommentData) => {
    try {
      const { data } = await client.get(
        `/api/v1/admins/webinar_lanes/${props.webinarLaneId}/webinars/${props.webinarId}/webinar_comments`
      )
      setWebinarComments(data)
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      handleAxiosError(error)
    }
  }

  const rejectedWebinarComments = () => {
    return webinarComments.filter(webinarComment => webinarComment.status === 'rejected')
  }

  const reviewingWebinarComments = () => {
    return webinarComments.filter(webinarComment => webinarComment.status === 'reviewing')
  }

  const notArchivedWebinarComments = () => {
    return webinarComments.filter(
      webinarComment => webinarComment.status === 'published' && !webinarComment.archived
    )
  }

  const archivedWebinarComments = () => {
    return webinarComments.filter(
      webinarComment => webinarComment.status === 'published' && webinarComment.archived
    )
  }

  const handleCommentClose = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await client.patch(
        `/api/v1/admins/webinar_lanes/${props.webinarLaneId}/webinars/${props.webinarId}/close_comment`
      )
    })
  }

  const handleCommentOpen = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await commentOpen(props.webinarLaneId, props.webinarId)
    })
  }

  const onClickQuestionnaireOpen = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await openQuestionnaire(props.webinarLaneId, props.webinarId)
    })
  }

  const onClickQuestionnaireClose = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await closeQuestionnaire(props.webinarLaneId, props.webinarId)
    })
  }

  useEffect(() => {
    fetchWebinarComments()
    createWebinarAdminChannel({
      webinarLaneId: props.webinarLaneId,
      handleCommentOpenedBroadcast,
      commentClose,
      handleAnnouncementOpened,
      handleAnnouncementClosed,
      handleQuestionnaireOpened,
      handleQuestionnaireClosed,
      handlePollAreaOpened,
      handlePollAreaClosed,
      handlePollQuestionCreated,
      handlePollQuestionBroadcast,
      handleAnnouncementUpdated,
      fetchWebinarComments,
    })
  }, [])

  return (
    <>
      <nav>
        <div className='nav nav-tabs'>
          <a className='nav-item nav-link active' data-toggle='tab' href='#comment'>
            チャット
          </a>
          <a className='nav-item nav-link' data-toggle='tab' href='#announcement'>
            アナウンス
          </a>
          <a className='nav-item nav-link' data-toggle='tab' href='#questionnaire'>
            振り返りシート
          </a>
          <a className='nav-item nav-link' data-toggle='tab' href='#poll'>
            投票
          </a>
        </div>
      </nav>
      <div className='tab-content'>
        <div className='tab-pane active' id='comment'>
          <div className='alert alert-success mt-2 d-flex align-items-center'>
            {commentEnabled ? (
              <>
                <div className='font-weight-bold'>現在、チャットフォーム表示中です。</div>
                <button className='btn btn-danger ml-3' type='button' onClick={handleCommentClose}>
                  チャットフォームを非表示にする
                </button>
              </>
            ) : (
              <>
                <div className='font-weight-bold'>現在、チャットフォームは表示されていません。</div>
                <button className='btn btn-primary ml-3' type='button' onClick={handleCommentOpen}>
                  チャットフォームを表示する
                </button>
              </>
            )}
          </div>
          <div className='row mt-5'>
            <div className='col-6'>
              <div className='h2 font-weight-bold mb-0'>
                <i className='mr-2 fa fa-lock' />
                未公開チャット
              </div>
              <div className='mb-3'>
                <small className='text-muted'>
                  並び順：更新日時の新しいものが上
                  <br />※
                  アクション（アーカイブ以外のステータス変更・チャット編集・返信投稿）があると更新日時が更新されます。
                </small>
              </div>
              <nav className='mb-2'>
                <div className='nav nav-tabs'>
                  <a className='nav-item nav-link active' data-toggle='tab' href='#reviewing'>
                    レビュー中
                  </a>
                  <a className='nav-item nav-link' data-toggle='tab' href='#rejected'>
                    公開却下済み
                  </a>
                </div>
              </nav>
              <div className='tab-content'>
                <div className='tab-pane active' id='reviewing'>
                  {reviewingWebinarComments().map(webinarComment => {
                    return (
                      <AdminWebinarCommentBox
                        key={webinarComment.id}
                        webinarLaneId={props.webinarLaneId}
                        webinarId={props.webinarId}
                        webinarComment={webinarComment}
                      />
                    )
                  })}
                </div>
                <div className='tab-pane' id='rejected'>
                  {rejectedWebinarComments().map(webinarComment => {
                    return (
                      <AdminWebinarCommentBox
                        key={webinarComment.id}
                        webinarLaneId={props.webinarLaneId}
                        webinarId={props.webinarId}
                        webinarComment={webinarComment}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
            <div className='col-6'>
              <div className='h2 font-weight-bold mb-0'>
                <i className='mr-2 fa fa-unlock' />
                公開済チャット
              </div>
              <div className='mb-3'>
                <small className='text-muted'>
                  並び順：更新日時の新しいものが上
                  <br />※
                  アクション（アーカイブ以外のステータス変更・チャット編集・返信投稿）があると更新日時が更新されます。
                </small>
              </div>

              <AdminWebinarCommentForm
                webinarLaneId={props.webinarLaneId}
                webinarId={props.webinarId}
              />

              <nav className='mt-4 mb-2'>
                <div className='nav nav-tabs'>
                  <a className='nav-item nav-link active' data-toggle='tab' href='#not-archived'>
                    未アーカイブ
                  </a>
                  <a className='nav-item nav-link' data-toggle='tab' href='#archived'>
                    アーカイブ済み
                  </a>
                </div>
              </nav>
              <div className='tab-content'>
                <div className='tab-pane active' id='not-archived'>
                  {notArchivedWebinarComments().map(webinarComment => {
                    return (
                      <AdminWebinarCommentBox
                        key={webinarComment.id}
                        webinarLaneId={props.webinarLaneId}
                        webinarId={props.webinarId}
                        webinarComment={webinarComment}
                      />
                    )
                  })}
                </div>
                <div className='tab-pane' id='archived'>
                  {archivedWebinarComments().map(webinarComment => {
                    return (
                      <AdminWebinarCommentBox
                        key={webinarComment.id}
                        webinarLaneId={props.webinarLaneId}
                        webinarId={props.webinarId}
                        webinarComment={webinarComment}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminWebinarAnnouncement
          enabled={announcementEnabled}
          content={announcementContent}
          submitAnnouncementOpen={submitAnnouncementOpen}
          submitAnnouncementClose={submitAnnouncementClose}
          submitAnnouncement={submitAnnouncement}
        />
        <div className='tab-pane' id='questionnaire'>
          <div className='d-flex alert alert-danger mt-2'>
            <div>
              {questionnaireEnabled ? (
                <>
                  <div className='font-weight-bold'>現在、振り返りシートのリンクを表示中です。</div>
                  <button
                    className='btn btn-danger btn-block mt-2'
                    type='button'
                    onClick={onClickQuestionnaireClose}
                  >
                    振り返りシートのリンクを非表示にする
                  </button>
                </>
              ) : (
                <>
                  <div className='font-weight-bold'>
                    現在、振り返りシートのリンクは表示されていません。
                  </div>
                  <button
                    className='btn btn-primary btn-block mt-2'
                    type='button'
                    onClick={onClickQuestionnaireOpen}
                  >
                    振り返りシートのリンクを表示する
                  </button>
                </>
              )}
            </div>
            <div className='flex-grow-1 card p-3 ml-5 text-body'>
              {props.questionnaireUrl === '' ? (
                <span>（振り返りシートURL未設定）</span>
              ) : (
                <div className='font-weight-bold'>
                  <div>振り返りシートにご回答をお願いいたします。</div>
                  <div>
                    <u>
                      <a
                        href={props.questionnaireUrl}
                        target='_blank'
                        rel='noreferrer'
                        className='h3 font-weight-bold text-danger'
                      >
                        &nbsp;こちら&nbsp;
                      </a>
                    </u>
                    からご回答いただけます。
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AdminWebinarPoll
          webinarLaneId={props.webinarLaneId}
          webinarId={props.webinarId}
          pollEnabled={pollEnabled}
          pollQuestions={pollQuestions}
        />
      </div>
    </>
  )
}

export default AdminWebinarConsole
