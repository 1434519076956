import React from 'react'
import { QuestionnaireComponentProps } from '../types/webinar_comment'

const Questionnaire: React.FC<QuestionnaireComponentProps> = props => {
  return (
    <>
      {props.questionnaireEnabled && (
        <div className='p-3 mb-3 wb-bg-yellow spec-questionnaire'>
          <div className='text-center text-white'>振り返りシートにご回答をお願いいたします</div>
          <a
            href={props.questionnaireUrl.replace('iwi_id', String(props.iwiId))}
            target='_blank'
            rel='noreferrer'
            className='btn wb-btn-yellow btn-block btn-lg mt-2 text-white font-weight-bold shadow-lg'
            onClick={props.handleClick}
          >
            振り返りシートに回答する
          </a>
        </div>
      )}
    </>
  )
}

export default Questionnaire
