import React, { useState } from 'react'
import { catchAxiosError } from '../../libs/catch_axios_error'
import { AdminWebinarCommentFormComponentProps } from '../../types/webinar_comment'
import { createCommentByAdmin } from '../../clients/webinarComments'

const AdminWebinarCommentForm: React.FC<AdminWebinarCommentFormComponentProps> = props => {
  const [inputContent, setInputContent] = useState<string>('')
  const [inputDisplayName, setInputDisplayName] = useState<string>('')

  const onChangeContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputContent(event.target.value)
  }

  const onChangeDisplayName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputDisplayName(event.target.value)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      const params = { content: inputContent, display_name: inputDisplayName }
      await createCommentByAdmin(props.webinarLaneId, props.webinarId, params)
      setInputContent('')
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-group mb-2'>
        <label htmlFor='webinar_comment_content'>
          <small>運営からのチャット</small>
        </label>
        <textarea
          id='webinar_comment_content'
          className='form-control'
          required
          rows={3}
          value={inputContent}
          onChange={onChangeContent}
        />
      </div>
      <div className='form-group mb-2'>
        <label htmlFor='webinar_comment_display_name'>
          <small>投稿者名（必須）</small>
        </label>
        <input
          id='webinar_comment_display_name'
          type='text'
          className='form-control'
          required
          onChange={onChangeDisplayName}
        />
        <small className='form-text text-muted'>
          投稿者名の後ろには「（セミナー運営）」の文字が自動付与されます。
        </small>
      </div>
      <button className='btn btn-block btn-sm btn-primary' type='submit'>
        送信する
      </button>
    </form>
  )
}

export default AdminWebinarCommentForm
