import * as Sentry from '@sentry/browser'

if (['production', 'staging'].indexOf(process.env.NODE_ENV) !== -1) {
  Sentry.init({
    dsn: 'https://104bd917b2404063bf106a1152da5961@o228580.ingest.sentry.io/5517528',
    tracesSampleRate: 0.0,
    environment: process.env.NODE_ENV,
  })
  if (window.APP.rails.user) {
    // eslint-disable-next-line camelcase
    const { admin, id, email, contact_id } = window.APP.rails.user
    Sentry.setUser({ admin, id, email, contact_id })
  }
}
