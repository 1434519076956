import React from 'react'
import { EmergencyCancelModalComponentProps } from '../types/webinar_comment'

const EmergencyCancelModal: React.FC<EmergencyCancelModalComponentProps> = props => {
  return (
    <>
      {props.emergencyCanceled && (
        <div className={'p-5 border border-danger rounded bg-white absolute-center w-50'}>
          <div className='h1 font-weight-bold'>システムに障害が発生しました</div>
          <div className='h6 mt-3'>
            大変申し訳ございません。システムに障害が発生したため、本配信は中止とさせていただきます。
            <br />
            視聴者のみなさまには、後日セミナー運営から別途ご連絡を差し上げます。
          </div>
        </div>
      )}
    </>
  )
}

export default EmergencyCancelModal
