import React from 'react'
import { WebinarReplyComponentProps } from '../types/webinar_comment'

const WebinarReplyBox: React.FC<WebinarReplyComponentProps> = ({ webinarReply }) => {
  return (
    <div className='bg-light mt-1 p-3 text-pre-wrap'>
      {!webinarReply.public && (
        <div className='mb-1'>
          <small className='text-muted'>あなたにのみ表示されています</small>
        </div>
      )}
      <div>{webinarReply.content}</div>
    </div>
  )
}

export default WebinarReplyBox
