import { AxiosError } from 'axios'
import Toastify from 'toastify-js'

type ResponseData = {
  errorMessages?: string[]
}

const showError = (message: string) => {
  Toastify({
    text: message,
    close: true,
    duration: -1,
    style: { background: '#e74a3b' },
  }).showToast()
}

const handleAxiosError = (error: AxiosError<ResponseData>) => {
  const { status, data } = error.response!

  switch (status) {
    case 401:
      showError(
        'セッションがタイムアウトしました。ブラウザの更新ボタンを押してページを再読込してください。解決しない場合はサポートまでお問い合わせください。'
      )
      break
    case 403:
      showError(
        '権限がありません。ブラウザの更新ボタンを押してページを再読込してください。解決しない場合はサポートまでお問い合わせください。'
      )
      break
    case 404:
      showError(
        '既に削除されている可能性があります。ブラウザの更新ボタンを押してページを再読込してください。解決しない場合はサポートまでお問い合わせください。'
      )
      break
    case 422:
      if (data && data.errorMessages) {
        data.errorMessages.forEach(message => showError(message))
      } else {
        // NOTE: InvalidAuthenticityToken や意図しない422エラー
        showError(
          'セッションがタイムアウトしました。ブラウザの更新ボタンを押してページを再読込してください。解決しない場合はサポートまでお問い合わせください。'
        )
      }
      break
    case 503:
      showError(
        'ただいまシステムメンテナンス中です。時間をあけてから再度アクセスしてください。解決しない場合はサポートまでお問い合わせください。'
      )
      break
    default:
      showError(
        'リクエストエラーが発生しました。ブラウザの更新ボタンを押してページを再読込してください。解決しない場合はサポートまでお問い合わせください。'
      )
  }
}

const catchAxiosError = async (fn: () => Promise<void>) => {
  try {
    await fn()
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    handleAxiosError(error)
  }
}

export { handleAxiosError, catchAxiosError }
