import React, { useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { WebinarPollComponentProps } from '../types/webinar_comment'
import client from '../libs/client'
import { catchAxiosError } from '../libs/catch_axios_error'

ChartJS.register(ArcElement, Tooltip, Legend)

const pieBackgroundColors = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
]

const pieBorderColors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
]

const WebinarPoll: React.FC<WebinarPollComponentProps> = props => {
  const [answeredPollQuestionIds, setAnsweredPollQuestionIds] = useState<number[]>(
    props.answeredPollQuestionIds
  )
  const [inputChoicedId, setInputChoicedId] = useState<number>()

  const submitPollAnswer = async (event: React.FormEvent<HTMLFormElement>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      const params = {
        poll_choice_id: inputChoicedId,
        special_seminar: props.specialSeminar ? true : null,
      }
      await client.post(
        `/api/v1/webinar_lanes/${props.webinarLaneId}/webinars/${props.webinarId}/poll_questions/${props.pollQuestion.id}/poll_answers`,
        params
      )
      setAnsweredPollQuestionIds(prevAnsweredPollQuestionIds => {
        return [...prevAnsweredPollQuestionIds, props.pollQuestion.id]
      })
    })
  }

  const changePollChoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputChoicedId(Number(event.target.value))
  }

  const pollResultData = () => {
    return {
      labels: props.pollQuestion.pollChoices.map(pollChoice => pollChoice.content),
      datasets: [
        {
          data: props.pollQuestion.pollChoices.map(pollChoice => pollChoice.votesAmount),
          backgroundColor: pieBackgroundColors,
          borderColor: pieBorderColors,
          borderWidth: 1,
        },
      ],
    }
  }

  const pollAnswered = (): boolean => {
    return answeredPollQuestionIds.includes(props.pollQuestion.id)
  }

  return (
    <div className='p-3 mb-3 bg-white border border-5 wb-border-yellow'>
      <div className='h4 font-weight-bold'>投票</div>
      {props.pollQuestion.status === 'opened' && !pollAnswered() && (
        <>
          <div className='text-pre-wrap mb-3'>{props.pollQuestion.content}</div>
          <form onSubmit={submitPollAnswer}>
            {props.pollQuestion.pollChoices.map(pollChoice => {
              return (
                <div className='form-check' key={pollChoice.id}>
                  <input
                    id={`poll_choice_${pollChoice.id}`}
                    type='radio'
                    className='form-check-input'
                    value={pollChoice.id}
                    onChange={changePollChoice}
                    checked={inputChoicedId === pollChoice.id}
                  />
                  <label className='form-check-label' htmlFor={`poll_choice_${pollChoice.id}`}>
                    {pollChoice.content}
                  </label>
                </div>
              )
            })}
            <button className='btn btn-block btn-primary mt-3' type='submit'>
              投票する
            </button>
          </form>
        </>
      )}
      {props.pollQuestion.status === 'opened' && pollAnswered() && <div>投票が完了しました。</div>}
      {props.pollQuestion.status === 'finished' && (
        <div className='pie-wrapper'>
          <Pie
            data={pollResultData()}
            options={{ maintainAspectRatio: false, responsive: false }}
            height={120}
          />
        </div>
      )}
    </div>
  )
}

export default WebinarPoll
