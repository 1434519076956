import createChannel from './cable'
import { CreateWebinarAudienceChannelArgsType } from '../types/webinar_comment'

const createWebinarAudienceChannel = ({
  webinarLaneId,
  commentOpen,
  commentClose,
  announcementOpen,
  announcementClose,
  handleQuestionnaireOpened,
  handleQuestionnaireClosed,
  handleEmergencyCanceled,
  handleEmergencyCancelReverted,
  handlePollAreaOpened,
  handlePollAreaClosed,
  handlePollQuestionBroadcast,
  handleAnnouncementUpdated,
  displayPublishedComment,
  handleCommentRejectRestored,
  handleCommentReviewed,
  handleWebinarActivated,
  handleVideoClosed,
  handleVideoSwitchedToWaiting,
  handleVideoSwitchedToNotWaiting,
  handleReplyUpdated,
  handleReplyDeleted,
  handleBroadcastPublicCommentUpdated,
  fetchCommentsInPrivate,
  displayPublicReply,
}: CreateWebinarAudienceChannelArgsType) => {
  createChannel({
    role: 'contact',
    params: {
      channel: 'WebinarAudienceChannel',
      webinarLaneId: webinarLaneId,
    },
    mixin: {
      received: (broadcastData: any) => {
        switch (broadcastData.type) {
          case 'comment_opened':
            commentOpen()
            break
          case 'comment_closed':
            commentClose()
            break
          case 'announcement_opened':
            announcementOpen()
            break
          case 'announcement_closed':
            announcementClose()
            break
          case 'announcement_updated':
            handleAnnouncementUpdated(broadcastData)
            break
          case 'questionnaire_opened':
            handleQuestionnaireOpened(broadcastData)
            break
          case 'questionnaire_closed':
            handleQuestionnaireClosed()
            break
          case 'emergency_canceled':
            handleEmergencyCanceled()
            break
          case 'emergency_cancel_reverted':
            handleEmergencyCancelReverted()
            break
          case 'poll_area_opened':
            handlePollAreaOpened()
            break
          case 'poll_area_closed':
            handlePollAreaClosed()
            break
          case 'poll_question_started':
          case 'poll_question_finished':
            handlePollQuestionBroadcast(broadcastData)
            break
          case 'comment_reject_restored':
            handleCommentRejectRestored(broadcastData)
            break
          case 'comment_reviewed':
            handleCommentReviewed(broadcastData)
            break
          case 'comment_published':
            displayPublishedComment(broadcastData)
            break
          case 'public_comment_updated':
            handleBroadcastPublicCommentUpdated(broadcastData)
            break
          case 'comment_created':
          case 'private_comment_updated':
          case 'private_reply_created':
          case 'private_reply_updated':
            fetchCommentsInPrivate(broadcastData)
            break
          case 'public_reply_created':
            displayPublicReply(broadcastData)
            break
          case 'public_reply_updated':
            handleReplyUpdated(broadcastData)
            break
          case 'reply_destroyed':
            handleReplyDeleted(broadcastData)
            break
          case 'webinar_activated':
            handleWebinarActivated(broadcastData)
            break
          case 'video_closed':
            handleVideoClosed()
            break
          case 'video_switched_to_waiting':
            handleVideoSwitchedToWaiting()
            break
          case 'video_switched_to_not_waiting':
            handleVideoSwitchedToNotWaiting()
            break
          default:
            break
        }
      },
    },
  })
}

export default createWebinarAudienceChannel
