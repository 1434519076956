import React, { useEffect, useState, useRef } from 'react'
import Hls from 'hls.js'
import client from '../libs/client'
import { csrfToken } from '@rails/ujs'
import { catchAxiosError, handleAxiosError } from '../libs/catch_axios_error'
import createWebinarAudienceChannel from '../channels/webinar_audience_channel'
import { fetchComments } from '../clients/webinarComments'
import ContentWithUrl from './ContentWithUrl'
import QuestionnaireEyeCatch from './QuestionnaireEyeCatch'
import EmergencyCancelModal from './EmergencyCancelModal'
import Questionnaire from './Questionnaire'
import WebinarCommentItem from './WebinarCommentItem'
import WebinarPoll from './WebinarPoll'
import {
  WebinarComment,
  PollQuestion,
  publicReplyCreatedData,
  BroadcastedAnnouncement,
  CommentRejectRestoredBroadcastData,
  CommentReviewedBroadcastData,
  ReplyUpdatedBroadcastData,
  ReplyDeletedBroadcastData,
  FetchCommentsInPrivateBroadcastData,
  WebinarActivatedBroadcastData,
  WebinarCommentBoxComponentProps,
  QuestionnaireOpenedData,
} from '../types/webinar_comment'

const WebinarCommentBox: React.FC<WebinarCommentBoxComponentProps> = props => {
  const [commentLoading, setCommentLoading] = useState<boolean>(false)
  const [submitProcessing, setSubmitProcessing] = useState<boolean>(false)
  const [webinarComments, setWebinarComments] = useState<WebinarComment[]>([])
  const [videoEnabled, setVideoEnabled] = useState<boolean>(props.videoEnabled)
  const [videoWaiting, setVideoWaiting] = useState<boolean>(props.videoWaiting)
  const [commentEnabled, setCommentEnabled] = useState<boolean>(props.commentEnabled)
  const [announcementEnabled, setAnnouncementEnabled] = useState<boolean>(props.announcementEnabled)
  const [announcementContent, setAnnouncementContent] = useState<string>(props.announcementContent)
  const [questionnaireEnabled, setQuestionnaireEnabled] = useState<boolean>(
    props.questionnaireEnabled
  )
  const [emergencyCanceled, setEmergencyCanceled] = useState<boolean>(props.emergencyCanceled)
  const [questionnaireUrl, setQuestionnaireUrl] = useState<string>(props.questionnaireUrl)
  const [pollEnabled, setPollEnabled] = useState<boolean>(props.pollEnabled)
  const [pollQuestion, setPollQuestion] = useState<PollQuestion>(props.pollQuestion)
  const [webinarId, setWebinarId] = useState<number>(props.webinarId)
  const [title, setTitle] = useState<string>(props.title)
  const [contactId] = useState<number>(props.contactId)
  const [inputContent, setInputContent] = useState<string>('')
  const [inputDisplayName, setInputDisplayName] = useState<string>('')
  const formInputRef = useRef<HTMLTextAreaElement>(null!)
  const commentsScrollArea = useRef<HTMLDivElement>(null!)
  const videoRef = useRef<HTMLVideoElement>(null!)
  const playerWaitMessageRef = useRef<HTMLDivElement>(null!)

  const commentOpen = async () => {
    setCommentEnabled(true)
  }

  const commentClose = async () => {
    setCommentEnabled(false)
  }

  const announcementOpen = async () => {
    setAnnouncementEnabled(true)
  }

  const announcementClose = async () => {
    setAnnouncementEnabled(false)
  }

  const handleQuestionnaireOpened = async (broadcastData: QuestionnaireOpenedData) => {
    setQuestionnaireUrl(broadcastData.questionnaireUrl)
    setQuestionnaireEnabled(true)
  }

  const handleQuestionnaireClosed = async () => {
    setQuestionnaireEnabled(false)
  }

  const handleEmergencyCanceled = async () => {
    setEmergencyCanceled(true)
  }

  const handleEmergencyCancelReverted = async () => {
    setEmergencyCanceled(false)
  }

  const handlePollAreaOpened = async () => {
    setPollEnabled(true)
  }

  const handlePollAreaClosed = async () => {
    setPollEnabled(false)
  }

  const handlePollQuestionBroadcast = async (broadcastData: PollQuestion) => {
    setPollQuestion(broadcastData)
  }

  const handleAnnouncementUpdated = async (broadcastedAnnouncement: BroadcastedAnnouncement) => {
    setAnnouncementContent(broadcastedAnnouncement.content)
  }

  const scrollComments = () => {
    const commentsScrollAreaElement = commentsScrollArea.current
    commentsScrollAreaElement.scrollTo(0, commentsScrollAreaElement.scrollHeight)
  }

  const handleWebinarActivated = async (broadcastData: WebinarActivatedBroadcastData) => {
    setWebinarId(broadcastData.webinarId)
    setTitle(broadcastData.title)
    setCommentEnabled(broadcastData.commentEnabled)
    setAnnouncementContent(broadcastData.announcementContent)
    setAnnouncementEnabled(broadcastData.announcementEnabled)
    setQuestionnaireUrl(broadcastData.questionnaireUrl)
    setQuestionnaireEnabled(broadcastData.questionnaireEnabled)
    setPollQuestion(broadcastData.pollQuestion)
    setPollEnabled(broadcastData.pollEnabled)
    setWebinarComments([])
    setCommentLoading(true)
    setTimeout(
      async () => {
        try {
          setWebinarComments(
            await fetchComments(props.webinarLaneId, broadcastData.webinarId, props.specialSeminar)
          )
          setCommentLoading(false)
          scrollComments()
        } catch (error: any) {
          if (!error.response) {
            throw error
          }
          handleAxiosError(error)
        }
      },
      // NOTE: 0秒 〜 9秒 の 10個のタイミングのいずれかでAPIが実行されるように
      Math.floor(Math.random() * 10) * 1000
    )
  }

  const handleVideoClosed = async () => {
    setVideoEnabled(false)
  }

  const handleVideoSwitchedToWaiting = async () => {
    setVideoWaiting(true)
  }

  const handleVideoSwitchedToNotWaiting = async () => {
    // NOTE: setVideoWaiting(false) だと再生されなかったので、リロードさせている
    // NOTE: 1秒後の実行にして、Railsのflushメッセージが表示されてしまうのを回避している
    setTimeout(() => {
      location.reload()
    }, 1000)
  }

  const fetchWebinarComments = async () => {
    try {
      setWebinarComments(await fetchComments(props.webinarLaneId, webinarId, props.specialSeminar))
      scrollComments()
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      handleAxiosError(error)
    }
  }

  const displayPublishedComment = async (broadcastedData: WebinarComment) => {
    setWebinarComments(prevWebinarComments => {
      const publishedCommentIndex = prevWebinarComments.findIndex(
        comment => comment.id === broadcastedData.id
      )
      if (publishedCommentIndex !== -1) {
        const comment = prevWebinarComments[publishedCommentIndex]
        comment.status = 'published'
        return [
          ...prevWebinarComments.slice(0, publishedCommentIndex),
          ...prevWebinarComments.slice(publishedCommentIndex + 1),
          comment,
        ]
      } else {
        return [...prevWebinarComments, broadcastedData]
      }
    })
    scrollComments()
  }

  const handleCommentRejectRestored = async (broadcastData: CommentRejectRestoredBroadcastData) => {
    setWebinarComments(prevWebinarComments => {
      const commentIndex = prevWebinarComments.findIndex(comment => comment.id === broadcastData.id)
      if (commentIndex !== -1) {
        const comment = prevWebinarComments[commentIndex]
        comment.status = 'reviewing'
        return [
          ...prevWebinarComments.slice(0, commentIndex),
          ...prevWebinarComments.slice(commentIndex + 1),
          comment,
        ]
      } else {
        return prevWebinarComments
      }
    })
    scrollComments()
  }

  const handleCommentReviewed = async (reviewedComment: CommentReviewedBroadcastData) => {
    setWebinarComments(prevWebinarComments => {
      const reviewedCommentIndex = prevWebinarComments.findIndex(
        comment => comment.id === reviewedComment.id
      )
      if (contactId === reviewedComment.contactId) {
        const comment = prevWebinarComments[reviewedCommentIndex]
        comment.status = 'reviewing'
        return [
          ...prevWebinarComments.slice(0, reviewedCommentIndex),
          ...prevWebinarComments.slice(reviewedCommentIndex + 1),
          comment,
        ]
      } else {
        return [
          ...prevWebinarComments.slice(0, reviewedCommentIndex),
          ...prevWebinarComments.slice(reviewedCommentIndex + 1),
        ]
      }
    })
    scrollComments()
  }

  const handleReplyDeleted = async (deletedReply: ReplyDeletedBroadcastData) => {
    setWebinarComments(prevWebinarComments => {
      const commentIndex = prevWebinarComments.findIndex(
        comment => comment.id === deletedReply.commentId
      )
      if (commentIndex !== -1) {
        const comment = prevWebinarComments[commentIndex]
        const replyIndex = comment.webinarReplies.findIndex(reply => reply.id === deletedReply.id)
        if (replyIndex !== -1) {
          const prevWebinarReplies = [...comment.webinarReplies]
          comment.webinarReplies = [
            ...prevWebinarReplies.slice(0, replyIndex),
            ...prevWebinarReplies.slice(replyIndex + 1),
          ]
          return [
            ...prevWebinarComments.slice(0, commentIndex),
            comment,
            ...prevWebinarComments.slice(commentIndex + 1),
          ]
        } else {
          return prevWebinarComments
        }
      } else {
        return prevWebinarComments
      }
    })
  }

  const handleBroadcastPublicCommentUpdated = async (broadcastedComment: WebinarComment) => {
    setWebinarComments(prevWebinarComments => {
      const publishedCommentIndex = prevWebinarComments.findIndex(
        comment => comment.id === broadcastedComment.id
      )
      if (publishedCommentIndex !== -1) {
        const comment = prevWebinarComments[publishedCommentIndex]
        comment.content = broadcastedComment.content
        return [
          ...prevWebinarComments.slice(0, publishedCommentIndex),
          ...prevWebinarComments.slice(publishedCommentIndex + 1),
          comment,
        ]
      } else {
        return prevWebinarComments
      }
    })
    scrollComments()
  }

  const fetchCommentsInPrivate = async (broadcastData: FetchCommentsInPrivateBroadcastData) => {
    if (contactId === broadcastData.contactId) {
      try {
        setWebinarComments(
          await fetchComments(props.webinarLaneId, broadcastData.webinarId, props.specialSeminar)
        )
        scrollComments()
      } catch (error: any) {
        if (!error.response) {
          throw error
        }
        handleAxiosError(error)
      }
    }
  }

  const displayPublicReply = async (publicReplyCreatedData: publicReplyCreatedData) => {
    setWebinarComments(prevWebinarComments => {
      const repliedWebinarCommentIndex = prevWebinarComments.findIndex(
        webinarComment => webinarComment.id === publicReplyCreatedData.commentId
      )
      if (repliedWebinarCommentIndex !== -1) {
        const reply = {
          id: publicReplyCreatedData.id,
          content: publicReplyCreatedData.content,
          public: publicReplyCreatedData.public,
        }
        const comment = prevWebinarComments[repliedWebinarCommentIndex]
        comment.webinarReplies.push(reply)
        return [
          ...prevWebinarComments.slice(0, repliedWebinarCommentIndex),
          ...prevWebinarComments.slice(repliedWebinarCommentIndex + 1),
          comment,
        ]
      }
      return prevWebinarComments
    })
    scrollComments()
  }

  const handleReplyUpdated = async (updatedReply: ReplyUpdatedBroadcastData) => {
    setWebinarComments(prevWebinarComments => {
      const commentIndex = prevWebinarComments.findIndex(
        comment => comment.id === updatedReply.commentId
      )
      if (commentIndex !== -1) {
        const comment = prevWebinarComments[commentIndex]
        const replyIndex = comment.webinarReplies.findIndex(reply => reply.id === updatedReply.id)
        if (replyIndex !== -1) {
          const prevWebinarReplies = [...comment.webinarReplies]
          const reply = prevWebinarReplies[replyIndex]
          reply.content = updatedReply.content
          comment.webinarReplies = [
            ...prevWebinarReplies.slice(0, replyIndex),
            reply,
            ...prevWebinarReplies.slice(replyIndex + 1),
          ]
          return [
            ...prevWebinarComments.slice(0, commentIndex),
            comment,
            ...prevWebinarComments.slice(commentIndex + 1),
          ]
        }
      }
      return prevWebinarComments
    })
  }

  const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputContent(event.target.value)
  }

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputDisplayName(event.target.value)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      if (submitProcessing) {
        return
      }
      setSubmitProcessing(true)
      const params = {
        content: inputContent,
        display_name: inputDisplayName,
        special_seminar: props.specialSeminar ? true : null,
      }
      await client.post(
        `/api/v1/webinar_lanes/${props.webinarLaneId}/webinars/${webinarId}/webinar_comments`,
        params
      )
      formInputRef.current.value = ''
      setInputContent('')
      setSubmitProcessing(false)
    })
  }

  const createWebinarPlayHistory = (recordType: string) => {
    const payload = new FormData()
    payload.append('authenticity_token', csrfToken() as string)
    payload.append('record_type', recordType)
    if (props.specialSeminar) {
      payload.append('special_seminar', 'true')
    }
    navigator.sendBeacon(
      `/api/v1/webinar_lanes/${props.webinarLaneId}/webinar_play_histories`,
      payload
    )
  }

  const handleQuestionnaireLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    createWebinarPlayHistory('questionnaire')
  }

  const handleQuestionnaireEyeCatchLinkClick = () => {
    createWebinarPlayHistory('questionnaire')
  }

  const handleBeforeUnloadEvent = (event: BeforeUnloadEvent) => {
    // ブラウザを閉じる挙動となるため退室扱い
    createWebinarPlayHistory('leave')
  }

  const pinnedComment = webinarComments
    .slice()
    .reverse()
    .find(comment => comment.byAdmin)

  const withoutPinnedComments = webinarComments.filter(comment => comment.id !== pinnedComment?.id)

  const setUpHls = () => {
    if (!videoEnabled || videoWaiting) {
      return
    }

    const videoElement = videoRef.current
    const playerWaitMessageElement = playerWaitMessageRef.current
    const videoSource = props.videoUrl

    videoElement.onplay = event => {
      playerWaitMessageElement.hidden = true
    }

    if (Hls.isSupported()) {
      const hls = new Hls()

      // NOTE: 配信開始直後はマニフェストファイル（.m3u8）がまだ出力されてないため、5秒ごとにリトライさせる
      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR) {
          setTimeout(() => hls.loadSource(videoSource), 5000)
        }
      })

      hls.loadSource(videoSource)
      hls.attachMedia(videoElement)
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = videoSource
    }
  }

  const onClickUnmuteButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    videoRef.current.muted = false
    event.currentTarget.hidden = true
  }

  useEffect(() => {
    fetchWebinarComments()
    createWebinarAudienceChannel({
      webinarLaneId: props.webinarLaneId,
      commentOpen,
      commentClose,
      announcementOpen,
      announcementClose,
      handleQuestionnaireOpened,
      handleQuestionnaireClosed,
      handleEmergencyCanceled,
      handleEmergencyCancelReverted,
      handlePollAreaOpened,
      handlePollAreaClosed,
      handlePollQuestionBroadcast,
      handleAnnouncementUpdated,
      displayPublishedComment,
      handleCommentRejectRestored,
      handleCommentReviewed,
      handleWebinarActivated,
      handleVideoClosed,
      handleVideoSwitchedToWaiting,
      handleVideoSwitchedToNotWaiting,
      handleReplyUpdated,
      handleReplyDeleted,
      handleBroadcastPublicCommentUpdated,
      fetchCommentsInPrivate,
      displayPublicReply,
    })
    setUpHls()
    window.addEventListener('beforeunload', handleBeforeUnloadEvent)
    return () => window.removeEventListener('beforeunload', handleBeforeUnloadEvent)
  }, [])

  return (
    <div className='container-fluid px-lg-5'>
      <QuestionnaireEyeCatch
        iwiId={props.iwiId}
        webinarId={webinarId}
        questionnaireEnabled={questionnaireEnabled}
        questionnaireUrl={questionnaireUrl}
        handleClick={handleQuestionnaireEyeCatchLinkClick}
      />

      <EmergencyCancelModal emergencyCanceled={emergencyCanceled} />

      <div className='row'>
        <div className='col-lg-8'>
          <div className='sticky-top'>
            {announcementEnabled && announcementContent && (
              <div className='d-flex align-items-center justify-content-center p-2 mb-3 bg-white'>
                <div className='wb-bg-yellow text-white p-2 text-nowrap'>運営者より</div>
                <ContentWithUrl
                  content={announcementContent}
                  className='ml-3 text-pre-wrap font-weight-bold h2 mb-0'
                />
              </div>
            )}
            <div className='position-relative w-100 player-wrapper'>
              {videoEnabled && !videoWaiting ? (
                <>
                  <video
                    ref={videoRef}
                    controls
                    controlsList='nodownload nofullscreen noplaybackrate'
                    disablePictureInPicture
                    autoPlay
                    muted
                    className='position-absolute w-100 h-100 top-0 left-0'
                  />
                  <div ref={playerWaitMessageRef} className='message-on-player'>
                    配信開始まで少々お待ちください。
                  </div>
                </>
              ) : !videoEnabled ? (
                <div className='message-on-player'>配信は終了しました。</div>
              ) : (
                <div className='message-on-player'>配信再開までしばらくお待ちください。</div>
              )}
              <button className='unmute-button' onClick={onClickUnmuteButton}>
                <i className='fa fa-volume-up mr-2' aria-hidden='true' />
                音声スタート
              </button>
            </div>

            <div className='py-3 px-4 bg-main'>
              <div className='text-white'>{props.webinarLaneName}</div>
              <div className='h2 mb-0 font-weight-bold text-white'>{title}</div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 mt-3 mt-lg-0'>
          <Questionnaire
            iwiId={props.iwiId}
            questionnaireEnabled={questionnaireEnabled}
            questionnaireUrl={questionnaireUrl}
            handleClick={handleQuestionnaireLinkClick}
          />

          <ul className='text-white font-weight-bold wb-bg-red pl-5 pr-3 py-2'>
            <li>通信環境の良い場所でご視聴ください。有線LANでの接続をおすすめいたします。</li>
            <li>
              開催時間になっても配信開始されない場合は、ページ更新または再読み込み（リロード・Ctrl+F5）をお願いいたします。
            </li>
            <li>
              入室後、自動では音声が出ない仕様になっております。動画の右下「音声スタート」ボタンを押してご視聴ください。（ページ更新した場合も同様に押下してください）
            </li>
            <li>
              万一トラブル等によりご視聴いただけなかった場合は、後日配信動画をご案内させていただきますので、ページ下部の「お問い合わせ窓口」までご連絡ください。
            </li>
          </ul>

          {pollEnabled && (
            <WebinarPoll
              pollQuestion={pollQuestion}
              webinarLaneId={props.webinarLaneId}
              webinarId={webinarId}
              answeredPollQuestionIds={props.answeredPollQuestionIds}
              specialSeminar={props.specialSeminar}
            />
          )}

          <div className='text-center text-secondary font-weight-bold bg-white py-1'>チャット</div>
          {commentLoading ? (
            <div className='text-center bg-white'>
              <i className='fa fa-spinner fa-spin' />
            </div>
          ) : (
            <>
              <div className='bg-white'>
                {pinnedComment && (
                  <WebinarCommentItem webinarComment={pinnedComment} pinned={true} />
                )}
              </div>
              <div ref={commentsScrollArea} className='comments__scroll-area'>
                <div className='bg-white'>
                  {withoutPinnedComments.map(webinarComment => {
                    return (
                      <WebinarCommentItem
                        key={webinarComment.id}
                        webinarComment={webinarComment}
                        pinned={false}
                      />
                    )
                  })}
                </div>
                <div className='wb-bg-blue p-3 pb-4 mb-5'>
                  {commentEnabled ? (
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label htmlFor='webinar_comment_display_name' className='text-white mb-1'>
                          <small>投稿者名（任意）</small>
                        </label>
                        <input
                          className='form-control'
                          type='text'
                          id='webinar_comment_display_name'
                          onChange={handleDisplayNameChange}
                        />
                      </div>
                      <div className='mt-1'>
                        <label htmlFor='webinar_comment_content' className='text-white mb-1'>
                          <small>コメント</small>
                        </label>
                        <textarea
                          className='form-control'
                          id='webinar_comment_content'
                          required
                          autoFocus
                          rows={3}
                          ref={formInputRef}
                          onChange={handleContentChange}
                        />
                      </div>
                      <button className='btn btn-sm btn-block btn-primary mt-2' type='submit'>
                        送信する
                      </button>
                    </form>
                  ) : (
                    <div className='text-white pt-2'>チャット投稿は締め切られています。</div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default WebinarCommentBox
