import React, { useState } from 'react'
import { AdminWebinarPollChoiceComponentProps } from '../../types/webinar_comment'
import { updatePollChoice } from '../../clients/poll_questions'
import { catchAxiosError } from '../../libs/catch_axios_error'

const AdminWebinarPollChoice: React.FC<AdminWebinarPollChoiceComponentProps> = props => {
  const [contentHidden, setContentHidden] = useState<boolean>(false)
  const [editFormHidden, setEditFormHidden] = useState<boolean>(true)
  const [inputContent, setInputContent] = useState<string>(props.pollChoice.content)

  const switchEditForm = (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // NOTE: 他の関数から呼ばれた場合は、event === undefined になる
    if (event !== undefined) {
      event.preventDefault()
    }
    setContentHidden(prevContentHidden => {
      setEditFormHidden(prevContentHidden)
      return !prevContentHidden
    })
  }

  const changeContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputContent(event.target.value)
  }

  const submitContentEdit = async (event: React.FormEvent<HTMLFormElement>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      const params = { content: inputContent }
      await updatePollChoice(
        props.webinarLaneId,
        props.webinarId,
        props.pollQuestion.id,
        props.pollChoice.id,
        params
      )
      switchEditForm()
    })
  }

  return (
    <div className='d-flex align-items-center mt-2'>
      <div className='bg-white px-3 py-2'>{props.pollChoice.votesAmount} 票</div>
      <div className='flex-grow-1 ml-2'>
        <div className='bg-white px-3 py-2' hidden={contentHidden}>
          {props.pollChoice.content}
        </div>
        <form hidden={editFormHidden} onSubmit={submitContentEdit} className='form-inline'>
          <input
            className='form-control flex-grow-1'
            type='text'
            required
            onChange={changeContent}
            value={inputContent}
          />
          <button className='btn btn-primary ml-1' type='submit'>
            更新する
          </button>
        </form>
      </div>
      {props.pollQuestion.status === 'waiting' && (
        <a href='#' onClick={switchEditForm}>
          <i className='ml-2 fa fa-edit' />
        </a>
      )}
    </div>
  )
}

export default AdminWebinarPollChoice
