import client from '../libs/client'

const updateReply = async (webinarLaneId: number, webinarId: number, webinarCommentId: number, webinarReplyId: number, content: string) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments/${webinarCommentId}/webinar_replies/${webinarReplyId}`,
    { content: content }
  )
}

const deleteReply = async (webinarLaneId: number, webinarId: number, webinarCommentId: number, webinarReplyId: number) => {
  await client.delete(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments/${webinarCommentId}/webinar_replies/${webinarReplyId}`
  )
}

export { updateReply, deleteReply }
