import client from '../libs/client'
import { WebinarComment } from '../types/webinar_comment'

const fetchComments = async (
  webinarLaneId: number,
  webinarId: number,
  specialSeminar: boolean
): Promise<WebinarComment[]> => {
  const { data } = await client.get(
    `/api/v1/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments${
      specialSeminar ? '?special_seminar=true' : ''
    }`
  )
  return data
}

const createCommentByAdmin = async (
  webinarLaneId: number,
  webinarId: number,
  // eslint-disable-next-line camelcase
  params: { content: string; display_name: string }
) => {
  await client.post(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments`,
    params
  )
}

const rejectComment = async (
  webinarLaneId: number,
  webinarId: number,
  webinarCommentId: number
) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments/${webinarCommentId}/reject`
  )
}

const restoreCommentReject = async (
  webinarLaneId: number,
  webinarId: number,
  webinarCommentId: number
) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments/${webinarCommentId}/restore_reject`
  )
}

const archiveComment = async (
  webinarLaneId: number,
  webinarId: number,
  webinarCommentId: number
) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments/${webinarCommentId}/archive`
  )
}

const restoreCommentArchive = async (
  webinarLaneId: number,
  webinarId: number,
  webinarCommentId: number
) => {
  await client.patch(
    `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments/${webinarCommentId}/restore_archive`
  )
}

export {
  fetchComments,
  createCommentByAdmin,
  rejectComment,
  restoreCommentReject,
  archiveComment,
  restoreCommentArchive,
}
