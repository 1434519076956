import React, { useState, useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import client from '../../libs/client'
import { catchAxiosError } from '../../libs/catch_axios_error'
import { AdminWebinarCommentComponentProps } from '../../types/webinar_comment'
import {
  rejectComment,
  restoreCommentReject,
  archiveComment,
  restoreCommentArchive,
} from '../../clients/webinarComments'
import AdminWebinarReplyBox from './AdminWebinarReplyBox'

const AdminWebinarCommentBox: React.FC<AdminWebinarCommentComponentProps> = ({
  webinarLaneId,
  webinarId,
  webinarComment,
}) => {
  const [inputContent, setInputContent] = useState<string>('')
  const [inputPublic, setInputPublic] = useState<boolean>(false)
  const [contentHidden, setContentHidden] = useState<boolean>(false)
  const [editFormHidden, setEditFormHidden] = useState<boolean>(true)
  const [inputCommentContent, setInputCommentContent] = useState<string>(webinarComment.content)
  const formInputRef = useRef<HTMLTextAreaElement>(null!)

  const handlePublishSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await client.patch(
        `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments/${webinarComment.id}/publish`
      )
    })
  }

  const handleArchiveSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await archiveComment(webinarLaneId, webinarId, webinarComment.id)
    })
  }

  const handleArchiveRestoreSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await restoreCommentArchive(webinarLaneId, webinarId, webinarComment.id)
    })
  }

  const handleReviewSubmit = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (window.confirm('本当に未公開状態に戻してよろしいですか？')) {
      await catchAxiosError(async () => {
        await client.patch(
          `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments/${webinarComment.id}/review`
        )
      })
    }
  }

  const handleRejectSubmit = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (window.confirm('本当に公開却下してよろしいですか？')) {
      await catchAxiosError(async () => {
        await rejectComment(webinarLaneId, webinarId, webinarComment.id)
      })
    }
  }

  const handleRejectRestoreSubmit = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    if (window.confirm('本当に公開却下を取り消してよろしいですか？')) {
      await catchAxiosError(async () => {
        await restoreCommentReject(webinarLaneId, webinarId, webinarComment.id)
      })
    }
  }

  const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputContent(event.target.value)
  }

  const handlePublicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPublic(event.target.checked)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      const params = {
        content: inputContent,
        public: inputPublic,
      }
      await client.post(
        `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments/${webinarComment.id}/webinar_replies`,
        params
      )
      formInputRef.current.value = ''
      setInputContent('')
      setInputPublic(false)
    })
  }

  const switchEditForm = (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // NOTE: 他の関数から呼ばれた場合は、event === undefined になる
    if (event !== undefined) {
      event.preventDefault()
    }
    setContentHidden(prevContentHidden => {
      setEditFormHidden(prevContentHidden)
      return !prevContentHidden
    })
  }

  const handleCommentContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputCommentContent(event.target.value)
  }

  const handleCommentEditSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      const params = { content: inputCommentContent }
      await client.patch(
        `/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/webinar_comments/${webinarComment.id}`,
        params
      )
      switchEditForm()
    })
  }

  const displayName = () => {
    if (webinarComment.displayName === '') {
      return '（投稿者名なし）'
    } else {
      return `${webinarComment.displayName}${webinarComment.byAdmin ? '（セミナー運営）' : ''}`
    }
  }

  return (
    <div
      className={`${
        webinarComment.byAdmin ? 'bg-orange' : 'border-bottom'
      } d-flex p-3 mb-3 spec-comment`}
    >
      <div className='flex-grow-1'>
        <div hidden={contentHidden} className='mb-5'>
          <div>
            <small className='text-break'>
              <i className='mr-1 fa fa-user' />
              {displayName()}
            </small>
          </div>
          <small className='text-muted'>投稿：{webinarComment.createdAt}</small>
          <small className='text-muted ml-3'>更新：{webinarComment.updatedAt}</small>
          <div className='text-break text-pre-wrap mt-3 spec-comment-content'>
            {webinarComment.content}
          </div>
        </div>
        <form hidden={editFormHidden} onSubmit={handleCommentEditSubmit}>
          <div className='form-group mt-2'>
            <textarea
              className='form-control'
              name='webinar_comment[content]'
              required
              rows={10}
              value={inputCommentContent}
              onChange={handleCommentContentChange}
            />
            <button className='btn btn-block btn-primary mt-3' type='submit'>
              更新する
            </button>
          </div>
        </form>
        {webinarComment.webinarReplies.length > 0 &&
          webinarComment.webinarReplies.map(webinarReply => {
            return (
              <AdminWebinarReplyBox
                key={webinarReply.id}
                webinarLaneId={webinarLaneId}
                webinarId={webinarId}
                webinarCommentId={webinarComment.id}
                webinarCommentArchived={webinarComment.archived}
                webinarReply={webinarReply}
              />
            )
          })}
        {!webinarComment.archived && (
          <details className='mt-3 spec-reply-details'>
            <summary>返信をする</summary>
            <form onSubmit={handleSubmit}>
              <div className='form-group mt-2'>
                <textarea
                  className='form-control'
                  name='webinar_reply[content]'
                  required
                  rows={10}
                  ref={formInputRef}
                  onChange={handleContentChange}
                />
                {webinarComment.status === 'published' && (
                  <div className='form-check mt-2'>
                    <input
                      id={`webinar_reply_public_${webinarComment.id}`}
                      type='checkbox'
                      className='form-check-input'
                      name='webinar_reply[public]'
                      onChange={handlePublicChange}
                      checked={inputPublic}
                    />
                    <label
                      className='form-check-label'
                      htmlFor={`webinar_reply_public_${webinarComment.id}`}
                    >
                      全員に公開する
                    </label>
                  </div>
                )}
                <button className='btn btn-block btn-primary mt-3' type='submit'>
                  送信する
                </button>
              </div>
            </form>
          </details>
        )}
      </div>
      <div className='d-flex flex-column ml-5'>
        {webinarComment.status === 'reviewing' && (
          <>
            <button
              className='btn btn-success mb-1 spec-publish-button'
              type='button'
              data-tip='チャットコメントを公開する'
              onClick={handlePublishSubmit}
            >
              <i className='fa fa-check' />
            </button>
            <ReactTooltip place='left' />
          </>
        )}
        {webinarComment.status === 'published' && !webinarComment.archived && (
          <>
            <button
              className='btn btn-outline-success mb-1'
              type='button'
              data-tip='アーカイブする'
              onClick={handleArchiveSubmit}
            >
              <i className='fa fa-arrow-down' />
            </button>
            <ReactTooltip place='left' />
          </>
        )}
        {webinarComment.status === 'published' && webinarComment.archived && (
          <>
            <button
              className='btn btn-outline-success mb-1'
              type='button'
              data-tip='未アーカイブに戻す'
              onClick={handleArchiveRestoreSubmit}
            >
              <i className='fa fa-arrow-up' />
            </button>
            <ReactTooltip place='left' />
          </>
        )}
        {!webinarComment.archived && (
          <div className='dropdown dropleft'>
            <a className='btn btn-outline-secondary mt-1' href='#' data-toggle='dropdown'>
              ...
            </a>
            <div className='dropdown-menu'>
              <a className='dropdown-item' href='#' onClick={switchEditForm}>
                <i className='mr-1 fa fa-edit' />
                編集する
              </a>
              {webinarComment.status === 'reviewing' && (
                <a className='dropdown-item' href='#' onClick={handleRejectSubmit}>
                  <i className='mr-1 fa fa-times' />
                  公開を却下する
                </a>
              )}
              {webinarComment.status === 'rejected' && (
                <a className='dropdown-item' href='#' onClick={handleRejectRestoreSubmit}>
                  <i className='mr-1 fa fa-arrow-up' />
                  公開却下を取り消す
                </a>
              )}
              {webinarComment.status === 'published' && (
                <a className='dropdown-item' href='#' onClick={handleReviewSubmit}>
                  <i className='mr-1 fa fa-lock' />
                  未公開状態に戻す
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminWebinarCommentBox
