import React, { useState } from 'react'
import { AdminWebinarPollQuestionComponentProps } from '../../types/webinar_comment'
import { updatePollQuestion, startPoll, finishPoll } from '../../clients/poll_questions'
import AdminWebinarPollChoice from './AdminWebinarPollChoice'
import { catchAxiosError } from '../../libs/catch_axios_error'

const AdminWebinarPollQuestion: React.FC<AdminWebinarPollQuestionComponentProps> = props => {
  const [contentHidden, setContentHidden] = useState<boolean>(false)
  const [editFormHidden, setEditFormHidden] = useState<boolean>(true)
  const [inputContent, setInputContent] = useState<string>(props.pollQuestion.content)
  const [inputPollChoiceContent, setInputPollChoiceContent] = useState<string>('')

  const changePollChoiceContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPollChoiceContent(event.target.value)
  }

  const onSubmitPollChoice = async (event: React.FormEvent<HTMLFormElement>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      props.submitPollChoice(props.pollQuestion.id, inputPollChoiceContent)
      setInputPollChoiceContent('')
    })
  }

  const onClickPollStart = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await startPoll(props.webinarLaneId, props.webinarId, props.pollQuestion.id)
    })
  }

  const onClickPollFinish = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await finishPoll(props.webinarLaneId, props.webinarId, props.pollQuestion.id)
    })
  }

  const switchEditForm = (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // NOTE: 他の関数から呼ばれた場合は、event === undefined になる
    if (event !== undefined) {
      event.preventDefault()
    }
    setContentHidden(prevContentHidden => {
      setEditFormHidden(prevContentHidden)
      return !prevContentHidden
    })
  }

  const onClickReload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    location.reload()
  }

  const changeContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputContent(event.target.value)
  }

  const submitContentEdit = async (event: React.FormEvent<HTMLFormElement>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      const params = { content: inputContent }
      await updatePollQuestion(props.webinarLaneId, props.webinarId, props.pollQuestion.id, params)
      switchEditForm()
    })
  }

  const statusText = {
    waiting: '準備中',
    opened: '投票中',
    finished: '結果発表済み',
  }

  return (
    <div className='alert alert-info p-3 mt-3'>
      <div className='text-muted'>
        {statusText[props.pollQuestion.status]}
        {props.pollQuestion.status === 'waiting' && (
          <a href='#' onClick={switchEditForm}>
            <i className='ml-1 fa fa-edit' />
          </a>
        )}
      </div>
      <div className='text-pre-wrap bg-white px-3 py-2 mt-2' hidden={contentHidden}>
        {props.pollQuestion.content}
      </div>
      <form hidden={editFormHidden} onSubmit={submitContentEdit} className='mt-2'>
        <textarea
          className='form-control'
          required
          rows={3}
          value={inputContent}
          onChange={changeContent}
        />
        <button className='btn btn-block btn-primary btn-sm mt-2' type='submit'>
          更新する
        </button>
      </form>
      {props.pollQuestion.pollChoices.map(pollChoice => {
        return (
          <AdminWebinarPollChoice
            key={pollChoice.id}
            webinarLaneId={props.webinarLaneId}
            webinarId={props.webinarId}
            pollQuestion={props.pollQuestion}
            pollChoice={pollChoice}
          />
        )
      })}
      {props.pollQuestion.status === 'waiting' && (
        <>
          <form onSubmit={onSubmitPollChoice} className='form-inline mt-2'>
            <input
              className='form-control flex-grow-1'
              type='text'
              required
              onChange={changePollChoiceContent}
              value={inputPollChoiceContent}
            />
            <button className='btn btn-primary ml-2' type='submit'>
              投票用選択肢を登録する
            </button>
          </form>
          <button
            className='btn btn-primary mt-2'
            type='button'
            onClick={onClickPollStart}
            disabled={!props.pollStartEnabled}
          >
            投票をスタートする
          </button>
        </>
      )}
      {props.pollQuestion.status === 'opened' && (
        <div className='mt-2'>
          <a href='#' className='btn btn-light border border-dark mr-2' onClick={onClickReload}>
            <i className='mr-1 fa fa-refresh' />
            更新
          </a>
          <button className='btn btn-danger' type='button' onClick={onClickPollFinish}>
            投票を締め切って結果を表示する
          </button>
        </div>
      )}
    </div>
  )
}

export default AdminWebinarPollQuestion
