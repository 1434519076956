import { createConsumer } from '@rails/actioncable'

// eslint-disable-next-line no-undef
const consumers: Record<string, TODO<'Consumer'>> = {}

type Role = 'admin' | 'contact'

const findOrCreateConsumer = (role: Role) => {
  if (!consumers[role]) {
    // eslint-disable-next-line no-undef
    consumers[role] = createConsumer(`/cable?role=${role}`) as TODO<'Consumer'>
  }
  return consumers[role]
}

type SubscriptionParams = {
  channel: string
  webinarLaneId: number
}

const createChannel = ({
  role,
  params,
  mixin,
}: {
  role: Role
  params: SubscriptionParams
  // eslint-disable-next-line no-undef
  mixin?: TODO<'mixin'>
}) => {
  const consumer = findOrCreateConsumer(role)
  return consumer.subscriptions.create(params, mixin)
}

export default createChannel
