import React from 'react'
import { QuestionnaireEyeCatchComponentProps } from '../types/webinar_comment'

const QuestionnaireEyeCatch: React.FC<QuestionnaireEyeCatchComponentProps> = props => {
  const localStorageKey = 'clickedQuestionnaireWebinarIds'

  const dNoneClass = () => {
    if (props.questionnaireEnabled) {
      const clickedQuestionnaireWebinarIds = localStorage.getItem(localStorageKey)
      if (
        clickedQuestionnaireWebinarIds !== null &&
        clickedQuestionnaireWebinarIds.split(',').includes(String(props.webinarId))
      ) {
        return 'd-none'
      }
    }
  }

  const setLocalStorage = () => {
    props.handleClick()
    const clickedQuestionnaireWebinarIds = localStorage.getItem(localStorageKey)
    if (clickedQuestionnaireWebinarIds === null) {
      localStorage.setItem(localStorageKey, String(props.webinarId))
    } else if (!clickedQuestionnaireWebinarIds.split(',').includes(String(props.webinarId))) {
      localStorage.setItem(localStorageKey, `${clickedQuestionnaireWebinarIds},${props.webinarId}`)
    }
    const element = document.querySelector('.js-questionnaire-eye-catch')
    element?.classList.add('d-none')
  }

  return (
    <>
      {props.questionnaireEnabled && (
        <div
          className={`${dNoneClass()} p-5 border border-danger rounded bg-white absolute-center w-50 js-questionnaire-eye-catch spec-questionnaire-eye-catch`}
        >
          <div className='h1 font-weight-bold'>振り返りシートにご回答をお願いいたします</div>
          <a
            href={props.questionnaireUrl.replace('iwi_id', String(props.iwiId))}
            target='_blank'
            rel='noreferrer'
            className='btn btn-danger btn-block btn-lg'
            onClick={setLocalStorage}
          >
            振り返りシートに回答する
          </a>
          <div className='h6 text-muted mt-3'>
            ボタンをクリックすることで、このモーダルは非表示となります。
            <br />
            ページ右上のボタンからでも、振り返りシートにアクセスできます。
          </div>
        </div>
      )}
    </>
  )
}

export default QuestionnaireEyeCatch
