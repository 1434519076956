import client from '../libs/client'

const createPollQuestion = async (webinarLaneId: number, webinarId: number, params: { content: string }) => {
  await client.post(`/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/poll_questions`, params)
}

const updatePollQuestion = async (webinarLaneId: number, webinarId: number, pollQuestionId: number, params: { content: string }) => {
  await client.patch(`/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/poll_questions/${pollQuestionId}`, params)
}

const createPollChoice = async (webinarLaneId: number, webinarId: number, pollQuestionId: number, params: { content: string }) => {
  await client.post(`/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/poll_questions/${pollQuestionId}/poll_choices`, params)
}

const updatePollChoice = async (webinarLaneId: number, webinarId: number, pollQuestionId: number, pollChoiceId: number, params: { content: string }) => {
  await client.patch(`/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/poll_questions/${pollQuestionId}/poll_choices/${pollChoiceId}`, params)
}

const startPoll = async (webinarLaneId: number, webinarId: number, pollQuestionId: number) => {
  await client.patch(`/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/poll_questions/${pollQuestionId}/start`)
}

const finishPoll = async (webinarLaneId: number, webinarId: number, pollQuestionId: number) => {
  await client.patch(`/api/v1/admins/webinar_lanes/${webinarLaneId}/webinars/${webinarId}/poll_questions/${pollQuestionId}/finish`)
}

export { updatePollQuestion, createPollQuestion, createPollChoice, updatePollChoice, startPoll, finishPoll }
