import createChannel from './cable'
import { CreateWebinarAdminChannelArgsType } from '../types/webinar_comment'

const createWebinarAdminChannel = ({
  webinarLaneId,
  handleCommentOpenedBroadcast,
  commentClose,
  handleAnnouncementOpened,
  handleAnnouncementClosed,
  handleQuestionnaireOpened,
  handleQuestionnaireClosed,
  handlePollAreaOpened,
  handlePollAreaClosed,
  handlePollQuestionCreated,
  handlePollQuestionBroadcast,
  handleAnnouncementUpdated,
  fetchWebinarComments,
}: CreateWebinarAdminChannelArgsType) => {
  createChannel({
    role: 'admin',
    params: {
      channel: 'WebinarAdminChannel',
      webinarLaneId: webinarLaneId,
    },
    mixin: {
      received: (broadcastData: any) => {
        switch (broadcastData.type) {
          case 'comment_opened':
            handleCommentOpenedBroadcast()
            break
          case 'comment_closed':
            commentClose()
            break
          case 'announcement_opened':
            handleAnnouncementOpened()
            break
          case 'announcement_closed':
            handleAnnouncementClosed()
            break
          case 'announcement_updated':
            handleAnnouncementUpdated(broadcastData)
            break
          case 'questionnaire_opened':
            handleQuestionnaireOpened()
            break
          case 'questionnaire_closed':
            handleQuestionnaireClosed()
            break
          case 'poll_area_opened':
            handlePollAreaOpened()
            break
          case 'poll_area_closed':
            handlePollAreaClosed()
            break
          case 'poll_question_created':
            handlePollQuestionCreated(broadcastData)
            break
          case 'poll_choice_created':
          case 'poll_choice_updated':
          case 'poll_question_updated':
          case 'poll_question_started':
          case 'poll_question_finished':
            handlePollQuestionBroadcast(broadcastData)
            break
          case 'comment_created':
          case 'comment_updated':
          case 'comment_rejected':
          case 'comment_reject_restored':
          case 'comment_reviewed':
          case 'comment_published':
          case 'comment_archived':
          case 'comment_archive_restored':
          case 'reply_created':
          case 'reply_updated':
          case 'reply_destroyed':
            fetchWebinarComments(broadcastData)
            break
          default:
            break
        }
      },
    },
  })
}

export default createWebinarAdminChannel
