import React, { useState } from 'react'
import { AdminWebinarAnnouncementComponentProps } from '../../types/webinar_comment'

const AdminWebinarAnnouncement: React.FC<AdminWebinarAnnouncementComponentProps> = props => {
  const [inputContent, setInputContent] = useState<string>(props.content)

  const onClickOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    props.submitAnnouncementOpen()
  }

  const onClickClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    props.submitAnnouncementClose()
  }

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputContent(event.target.value)
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    props.submitAnnouncement(inputContent)
  }

  return (
    <div className='tab-pane' id='announcement'>
      <div className='alert alert-warning mt-2'>
        <div className='d-flex'>
          <div>
            {props.enabled ? (
              <>
                <div className='font-weight-bold'>現在、アナウンス表示中です。</div>
                <button
                  className='btn btn-danger btn-block mt-2'
                  type='button'
                  onClick={onClickClose}
                >
                  アナウンスを非表示にする
                </button>
              </>
            ) : (
              <>
                <div className='font-weight-bold'>現在、アナウンスは表示されていません。</div>
                <button
                  className='btn btn-primary btn-block mt-2'
                  type='button'
                  onClick={onClickOpen}
                >
                  アナウンスを表示する
                </button>
              </>
            )}
          </div>
          <div className='flex-grow-1 card p-3 ml-5 text-body text-pre-wrap'>
            {props.content === '' ? <span>（文言未設定）</span> : props.content}
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-6'>
            <div className='h5 font-weight-bold'>例文</div>
            <ul className='pl-4'>
              <li>●時●分より開会いたします。今しばらくお待ちください。</li>
              <li>次の講座は●時●分より開始いたします。今しばらくお待ちください。</li>
              <li>
                接続不良などで、万が一画面が止まってしまった場合は、ページの更新をお願いいたします。
              </li>
              <li>
                すぐに活用できる特別ノウハウや限定DL資料が多数入手いただける期間限定イベント
                「ビジネスパートナーフェア」は画面下部をクリック！
              </li>
              <li>
                講座内容に関する質問や感想・気付きは、画面右側のチャットにて投稿いただけますので、積極的にご活用ください。
              </li>
            </ul>
          </div>

          <form onSubmit={onSubmit} className='col-6'>
            <div className='form-group'>
              <textarea
                className='form-control'
                rows={5}
                onChange={onChange}
                value={inputContent}
              />
              <button className='btn btn-primary btn-sm btn-block mt-2' type='submit'>
                更新する
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AdminWebinarAnnouncement
