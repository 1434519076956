import React from 'react'
import DOMPurify from 'dompurify'
import url2a from '../libs/url2a'
import { ContentWithUrlComponentProps } from '../types/webinar_comment'

const ContentWithUrl: React.FC<ContentWithUrlComponentProps> = props => {
  return (
    <div
      className={props.className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(url2a(props.content), {
          ALLOWED_TAGS: ['a'],
          ALLOWED_ATTR: ['href', 'target'],
        }),
      }}
    />
  )
}

export default ContentWithUrl
