import React, { useState } from 'react'
import { AdminWebinarReplyComponentProps } from '../../types/webinar_comment'
import { updateReply, deleteReply } from '../../clients/webinarReplies'
import { catchAxiosError } from '../../libs/catch_axios_error'

const AdminWebinarReplyBox: React.FC<AdminWebinarReplyComponentProps> = ({
  webinarLaneId,
  webinarId,
  webinarCommentId,
  webinarCommentArchived,
  webinarReply,
}) => {
  const [contentHidden, setContentHidden] = useState<boolean>(false)
  const [editFormHidden, setEditFormHidden] = useState<boolean>(true)
  const [inputEditContent, setInputEditContent] = useState<string>(webinarReply.content)

  const switchEditForm = (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // NOTE: 他の関数から呼ばれた場合は、event === undefined になる
    if (event !== undefined) {
      event.preventDefault()
    }
    setContentHidden(prevContentHidden => {
      setEditFormHidden(prevContentHidden)
      return !prevContentHidden
    })
  }

  const handleEditContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputEditContent(event.target.value)
  }

  const handleEditSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await updateReply(
        webinarLaneId,
        webinarId,
        webinarCommentId,
        webinarReply.id,
        inputEditContent
      )
      switchEditForm()
    })
  }

  const handleDelete = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (window.confirm('本当に削除してよろしいですか？')) {
      await catchAxiosError(async () => {
        await deleteReply(webinarLaneId, webinarId, webinarCommentId, webinarReply.id)
      })
    }
  }

  return (
    <div className='bg-light mt-1 p-3 text-pre-wrap d-flex'>
      <div className='flex-grow-1'>
        <small className='text-muted'>{webinarReply.public ? '公開済み' : '非公開'}</small>
        <div hidden={contentHidden} className='mt-1'>
          {webinarReply.content}
        </div>
        <form hidden={editFormHidden} onSubmit={handleEditSubmit}>
          <div className='form-group mt-2'>
            <textarea
              className='form-control'
              name='webinar_reply[content]'
              required
              rows={10}
              value={inputEditContent}
              onChange={handleEditContentChange}
            />
            <button className='btn btn-block btn-primary mt-3' type='submit'>
              更新する
            </button>
          </div>
        </form>
      </div>
      {!webinarCommentArchived && (
        <div className='dropdown dropleft ml-5'>
          <a className='btn btn-secondary' href='#' data-toggle='dropdown'>
            ...
          </a>
          <div className='dropdown-menu'>
            <a className='dropdown-item' href='#' onClick={switchEditForm}>
              <i className='mr-1 fa fa-edit' />
              編集する
            </a>
            <a className='dropdown-item' href='#' onClick={handleDelete}>
              <i className='mr-1 fa fa-trash' />
              削除する
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminWebinarReplyBox
