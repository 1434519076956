import React, { useRef, useState } from 'react'
import { catchAxiosError } from '../../libs/catch_axios_error'
import {
  AdminWebinarPollComponentProps,
  PollQuestion,
  SubmitPollChoice,
} from '../../types/webinar_comment'
import { openPollArea, closePollArea } from '../../clients/webinars'
import { createPollQuestion, createPollChoice } from '../../clients/poll_questions'
import AdminWebinarPollQuestion from './AdminWebinarPollQuestion'

const AdminWebinarPoll: React.FC<AdminWebinarPollComponentProps> = props => {
  const [inputPollQuestionContent, setInputPollQuestionContent] = useState<string>('')
  const formInputPollQuestionContentRef = useRef<HTMLTextAreaElement>(null!)

  const onClickPollAreaOpen = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await openPollArea(props.webinarLaneId, props.webinarId)
    })
  }

  const onClickPollAreaClose = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      await closePollArea(props.webinarLaneId, props.webinarId)
    })
  }

  const submitPollQuestion = async (event: React.FormEvent<HTMLFormElement>) => {
    await catchAxiosError(async () => {
      event.preventDefault()
      const params = { content: inputPollQuestionContent }
      await createPollQuestion(props.webinarLaneId, props.webinarId, params)
      formInputPollQuestionContentRef.current.value = ''
      setInputPollQuestionContent('')
    })
  }

  const changePollQuestionContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputPollQuestionContent(event.target.value)
  }

  const pollStartEnabled = (pollQuestion: PollQuestion) => {
    return (
      pollQuestion.status === 'waiting' &&
      pollQuestion.pollChoices.length > 1 &&
      props.pollQuestions.findIndex(pollQuestion => pollQuestion.status === 'opened') === -1
    )
  }

  const submitPollChoice: SubmitPollChoice = async (pollQuestionId, content) => {
    const params = { content: content }
    await createPollChoice(props.webinarLaneId, props.webinarId, pollQuestionId, params)
  }

  return (
    <div className='tab-pane' id='poll'>
      <div className='alert alert-info mt-2'>
        <div className='d-flex'>
          <div>
            {props.pollEnabled ? (
              <>
                <div className='font-weight-bold'>現在、投票エリア表示中です。</div>
                <button
                  className='btn btn-danger btn-block mt-2'
                  type='button'
                  onClick={onClickPollAreaClose}
                >
                  投票エリアを非表示にする
                </button>
              </>
            ) : (
              <>
                <div className='font-weight-bold'>現在、投票エリアは表示されていません。</div>
                <button
                  className='btn btn-primary btn-block mt-2'
                  type='button'
                  onClick={onClickPollAreaOpen}
                >
                  投票エリアを表示する
                </button>
              </>
            )}
          </div>
          <div className='flex-grow-1 ml-5'>
            <form onSubmit={submitPollQuestion}>
              <div className='form-group mt-2'>
                <textarea
                  className='form-control'
                  required
                  rows={3}
                  onChange={changePollQuestionContent}
                  ref={formInputPollQuestionContentRef}
                />
                <button className='btn btn-block btn-primary btn-sm mt-2' type='submit'>
                  投票用質問を登録する
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {props.pollQuestions.map(pollQuestion => {
        return (
          <AdminWebinarPollQuestion
            key={pollQuestion.id}
            webinarLaneId={props.webinarLaneId}
            webinarId={props.webinarId}
            pollQuestion={pollQuestion}
            pollStartEnabled={pollStartEnabled(pollQuestion)}
            submitPollChoice={submitPollChoice}
          />
        )
      })}
    </div>
  )
}

export default AdminWebinarPoll
